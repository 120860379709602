import React from "react"
import NavContextProvider from "./NavContext"

export const GlobalContext = React.createContext()

const GlobalContextProvider = ({ children }) => {
  return (
    <GlobalContext.Provider>
      <NavContextProvider>{children}</NavContextProvider>
    </GlobalContext.Provider>
  )
}

export default GlobalContextProvider
