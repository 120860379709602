import React from "react"
import { useMachine, createMachi } from "@xstate/react"
import { Machine, assign } from "xstate"
export const NavStateContext = React.createContext()
export const NavDispatchContext = React.createContext()

const navMachine = Machine(
  {
    id: "global",
    initial: "menuInactive",
    context: {
      isMenuClicked: false,
      scrollTarget: "main",
    },
    states: {
      menuInactive: {
        on: {
          TOGGLE: {
            target: "menuActive",
            actions: "toggleMenu",
          },
          SCROLLED: {
            target: "menuInactive",
            actions: "setScrollTarget",
          },
        },
      },
      menuActive: {
        on: {
          TOGGLE: {
            target: "menuInactive",
            actions: "toggleMenu",
          },
          SCROLLED: {
            target: "menuInactive",
            actions: "setScrollTarget",
          },
        },
      },
    },
  },
  {
    actions: {
      toggleMenu: assign({
        isMenuClicked: (ctx, event) => {
          return !ctx.isMenuClicked
        },
      }),
      setScrollTarget: assign({
        scrollTarget: (ctx, event) => {
          return event.target
        },
      }),
    },
  }
)

const NavContextProvider = ({ children }) => {
  const [current, send] = useMachine(navMachine)
  return (
    <NavStateContext.Provider value={current}>
      <NavDispatchContext.Provider value={send}>
        {children}
      </NavDispatchContext.Provider>
    </NavStateContext.Provider>
  )
}

export default NavContextProvider
